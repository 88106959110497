<template>
    <div class="gzjh">
        <div>
            <Intro></Intro>
            <ContactUs></ContactUs>
        </div>

        <div class="gzjh_m">
            <div class="part_header">
                <router-link to="/">首页</router-link> > 工作机会
            </div>
            <div class="gzjh_content">
                <h3>工作机会</h3>
                <p>正在更新......</p>
            </div>
        </div>
    </div>
</template>
<script>
    import Intro from "@/components/common/intro.vue"
    import ContactUs from "@/components/common/contactus.vue";
    export default {
        name: 'Gywm',
        components: {
            Intro,
            ContactUs
        }
    }
</script>
<style lang="less" scoped>
    .gzjh {
        margin-top: 13px;
        display: flex;
        justify-content: space-between;

        .gzjh_m {
            width: 700px;
            margin-bottom: 520px;



            p {
                color: rgb(127, 127, 127);
                line-height: 1.75em;
                font-size: 16px;
            }

            h3 {
                color: rgb(44, 90, 168);
                margin: 30px 0 25px;
                font-size: 16px;
            }
        }
    }
</style>