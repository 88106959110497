<template>
    <div class="intro">
        <h3>瞻世趣简介</h3>
        <img src="../../assets/intro.png" alt="">
        <p>瞻世趣科技发展（北京）有限公司</p>
        <p>Zing Super Quire Technology & Development Co., Ltd.</p>
    </div>
</template>
<script>
    export default {
        name: 'Intro'
    }
</script>
<style lang="less">
    .intro {
        width: 250px;
        height: 260px;
        background: #2c5aa8;
        margin-bottom: 15px;

        h3 {
            color: #fff;
            text-align: center;
            height: 60px;
            line-height: 60px;
        }

        img {
            display: block;
            margin: 10px auto 0;
            width: 80%;
        }

        p:first-of-type {
            font-size: 14px;
            font-weight: bold;
            color: #fff;
            text-align: center;
            margin-top: 15px;
        }

        p:last-of-type {
            color: rgb(219, 229, 241);
            font-family: arial, helvetica, sans-serif;
            font-size: 10px;
            text-align: center;
            width: 94%;
            line-height: 1.5;
        }
    }
</style>